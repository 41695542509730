<script setup lang="ts">
const colorMode = useColorMode()
const isDark = computed({
  get () {
    return colorMode.value === 'dark'
  },
  set () {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})
</script>

<template>
  <client-only>
    <template v-if="isDark">
      <img src="/logo-dark.png" alt="Logo" />
    </template>
    <template v-else>
      <img src="/logo-light.png" alt="Logo" />
    </template>
  </client-only>
</template>
